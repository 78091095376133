import React, { ReactNode } from 'react'
import Container from 'components/Container/Container'
import LayoutBase from '../LayoutBase/LayoutBase'
import Logo from 'components/Logo/Logo'
import Shapes from 'components/Shapes/Shapes'
import Footer from 'components/Footer/Footer'

import './LayoutLegal.css'

interface Props {
  children: ReactNode
}

const LayoutLegal = ({ children }: Props) => {
  return (
    <LayoutBase>
      <div
        style={{ minWidth: '30rem' }}
        className="layout-legal w-full bg-sensitive-grey text-rich-blue-darkest text-sm flex flex-col justify-between min-h-screen"
      >
        <Container className="layout-legal__container">
          <main
            id="main"
            className="layout-legal__main mr-auto -ml-1 md:-ml-3.5 md:px-10 max-w-full"
          >
            <Logo dark className="mb-12" />
            {children}
          </main>
        </Container>
        <Shapes />
        <Footer compact />
      </div>
    </LayoutBase>
  )
}

export default LayoutLegal

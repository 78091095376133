import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
  style?: React.CSSProperties
}

const Container = ({ children, className, style }: Props) => {
  const st = { minWidth: '30rem', ...(style || {}) }
  return (
    <div
      style={st}
      className={`container mx-auto min-h-full pt-6 pb-10 px-6 ${
        className || ''
      }`}
    >
      {children}
    </div>
  )
}

export default Container
